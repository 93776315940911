module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GIG Media","short_name":"GIG Media","start_url":"/","display":"standalone","icon":"src/images/favicon.png","crossOrigin":"use-credentials","cache_busting_mode":"none","icons":[{"src":"/favicon.ico","sizes":"64x64","type":"image/ico"}],"legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-core-theme/gatsby-browser.js'),
      options: {"plugins":[],"siteName":"fussballstreams.com","preconnectLinks":["https://www.google-analytics.com","https://www.googletagmanager.com","https://gm.innocraft.cloud","https://assets-srv.s3.eu-west-1.amazonaws.com","https://d3449cb8ihm3k3.cloudfront.net"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
